import '../css/app.scss';
import 'bootstrap';
import 'slick-carousel';
import _ from 'lodash';
import Plyr from 'plyr';
import DataTable from 'datatables.net-dt';
import { gsap } from 'gsap';
import SplitText from 'gsap/SplitText';
import ScrollTrigger from 'gsap/ScrollTrigger';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';
import Masonry from 'masonry-layout';
import PhotoSwipeLightbox from 'photoswipe/lightbox';

$(() => {
	$('.js-navigation-button').click(() => {
		$('.header').toggleClass('header--navigation-open');
		$('.desktop-navigation').toggleClass('desktop-navigation--open');
		$('.mobile-navigation').toggleClass('mobile-navigation--open');
	});

	var prevScrollpos = window.pageYOffset;
	var headerDiv = document.querySelector('header');
	var headerBottom = 0;
	// var headerBottom = headerDiv.offsetTop + headerDiv.offsetHeight;

	window.addEventListener(
		'scroll',
		_.throttle(function () {
			if ($(headerDiv).hasClass('header--navigation-open') === false) {
				var currentScrollPos = window.pageYOffset;

				console.log('currentScrollPos', currentScrollPos);
				if (
					(prevScrollpos > currentScrollPos ||
					currentScrollPos < headerBottom) || 
					currentScrollPos <= 0
				) {
					if (currentScrollPos <= headerBottom) {
						headerDiv.classList.remove('header--background');	
					} else {
						headerDiv.classList.add('header--background');
					}
					headerDiv.style.transform = 'translate(0, 0)';
				} else {
					headerDiv.style.transform = 'translate(0, -100%)';
				}
			}

			prevScrollpos = currentScrollPos;
		}, 50)
	);

	$('.image-teaser-carousel').slick({
		infinite: false,
		arrows: false,
		dots: false,
		slidesToShow: 3,
		adaptiveHeight: false,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1.5,
				},
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2.5,
				},
			},
		],
	});

	$('.progress-carousel').slick({
		infinite: false,
		arrows: false,
		dots: false,
		slidesToShow: 3.5,
		responsive: [
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1.2,
				},
			},
			{
				breakpoint: 993,
				settings: {
					slidesToShow: 1.5,
				},
			},
			{
				breakpoint: 1601,
				settings: {
					slidesToShow: 2.5,
				},
			},
		],
	});

	$('.progress-carousel').on(
		'beforeChange',
		function (event, slick, currentSlide, nextSlide) {
			let slidesToShowPerPage = Math.floor(
				$('.progress-carousel').slick('slickGetOption', 'slidesToShow')
			);
			const slide = nextSlide + 1;
			const slideCount = slick.slideCount;
			const pageCount = slideCount - slidesToShowPerPage + 1;
			const width = (slide / pageCount) * 100;
			$('.progress-carousel__progress-bar').css('width', width + '%');
		}
	);

	// Video Player
	const $videoPlayers = $('.video-player');

	$videoPlayers.each(function (index, element) {
		new Plyr(element);
	});

	$('.image-carousel__carousel').slick({
		infinite: true,
		arrows: false,
		dots: false,
		slidesToShow: 1,
	});

	$('.image-carousel__carousel').on(
		'beforeChange',
		function (event, slick, currentSlide, nextSlide) {
			const currentSlideNumberContainer = $(this)
				.parent()
				.find('.image-carousel__current-slide-number');
			currentSlideNumberContainer.html(String(nextSlide + 1).padStart(2, '0'));

			const captionContainer = $(this)
				.parent()
				.find('.image-carousel__caption');
			const caption = $(slick.$slides[nextSlide])
				.find('.image-carousel__image img')
				.attr('alt');

			captionContainer.html(caption);
		}
	);

	$('.image-carousel__arrow--left').click((e) => {
		console.log('e', e);
		const $target = $(e.currentTarget);
		const $carousel = $target.closest('.image-carousel').find('.image-carousel__carousel');
		$carousel.slick('slickPrev');
	});

	$('.image-carousel__arrow--right').click((e) => {
		console.log('e', e);
		const $target = $(e.currentTarget);

		// find parent .image-carousel__carousel
		const $carousel = $target.closest('.image-carousel').find('.image-carousel__carousel');
		
		$carousel.slick('slickNext');
	});

	new DataTable('#data-table', {
		paging: false,
		info: false,
		scrollY: true,
		columns: [
			null,
			null,
			null,
			null,
			{ searchable: false, orderable: false },
			{ searchable: false, orderable: false },
		],
		language: {
			searchPlaceholder: 'Stichwort eingeben...',
			zeroRecords: 'Keine Einträge gefunden',
		},
	});

	gsap.registerPlugin(SplitText, ScrollTrigger, DrawSVGPlugin);
	var splitText = new SplitText('.text-reveal-animation', {
		type: 'lines',
	});

	$(splitText.lines).each(function (index, element) {
		$(element).addClass('text-reveal-animation__line');
		element.innerHTML =
			"<div class='text-reveal-animation__inner'>" +
			element.innerHTML +
			'</div>';
	});

	$(splitText.elements).each(function (index, element) {
		ScrollTrigger.create({
			trigger: element,
			start: 'top 80%',
			markers: false,
			onEnter: () => {
				$(element).addClass('text-reveal-animation--reveal');
			},
		});
	});

	// window resize debounce
	$(window).resize(_.debounce(() => {
		// recalculate split text
		splitText.revert();

	}, 100));


	$('.background-reveal-animation').each(function (index, element) {
		ScrollTrigger.create({
			trigger: element,
			start: '65% bottom',
			end: 'bottom top',
			markers: false,
			onEnter: () => {
				console.log('enter');
				$(element).addClass('background-reveal-animation--reveal');
			},
		});
	});

	const $loops = $('[data-loop="el"]');

	$loops.each(function (index, element) {
		const $loop = $(element).find('[data-loop="loop"]');

		// check if data-reverse exists
		const isReverse = $loop.data('reverse') && $loop.data('reverse') === true ? true : false;

		
		console.log('isReverse', isReverse);
		console.log("$(element).data('reverse')", $(element).data('reverse'));
		gsap.set($loop, {drawSVG: 0});
		ScrollTrigger.create({
			trigger: element,
			start: 'top 60%',
			end: 'bottom top',
			markers: false,
			onEnter: () => {
				gsap.to($loop.find('path'), {duration: 1.5, drawSVG: isReverse ? '-100%' : '100%' , ease: 'linear'});
				// gsap.from($(element).find('path'), {duration: 1.5, drawSVG: 0});
				// gsap.from(element, {duration:1,drawSVG: 0});
			},
		});
	});

	// check how many direct children are in main and add class to footer if its even or odd
	const $main = $('main');
	const $footer = $('footer');

	if ($main.children().length % 2 === 0) {
		$footer.addClass('footer--even');
	} else {
		$footer.addClass('footer--odd');
	}

	// Masonry
	const $masonry = $('.masonry');

	if($masonry.length > 0) {
		const masonry = new Masonry('.masonry', {
			itemSelector: '.masonry__item',
			columnWidth: '.masonry__item',
			percentPosition: true,
			horizontalOrder: true,
		});

		// Lightbox
		const lightbox = new PhotoSwipeLightbox({
			gallery: '.masonry',
			children: 'a',
			pswpModule: () => import('photoswipe'),
			bgOpacity: 1,
			paddingFn: (viewportSize, itemData, index) => {
				return {
					top: viewportSize.x > 1200 ? 82 : 0,
					bottom: viewportSize.x > 1200 ? 100 : 0,
					left: viewportSize.x > 1200 ? 100 : 0,
					right: viewportSize.x > 1200 ? 100 : 0
				}
 			}
		});
		lightbox.init();
	}

	// Google Maps
	const $map = $('[data-map="el"]');

	if($map.length > 0) {

		// get the primary color from the css
		// --primary-color: #2D558C;
		const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color');

		const lat = $map.data('lat');
		const lng = $map.data('lng');
		const map = new google.maps.Map($map[0], {
			center: { lat: lat, lng: lng },
			zoom: 16,
			mapTypeControl: false,
			streetViewControl: false,
			draggable: false,
			scrollwheel: false,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
		});

		map.set('styles', [
			{
				featureType: 'administrative',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#444444' }],
			},
			{
				featureType: 'landscape',
				elementType: 'geometry.fill',
				stylers: [{ color: '#f2f2f2' }],
			},
			{
				featureType: 'poi',
				elementType: 'all',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'road',
				elementType: 'all',
				stylers: [{ saturation: -100 }, { lightness: 45 }],
			},
			{
				featureType: 'road.highway',
				elementType: 'all',
				stylers: [{ visibility: 'simplified' }],
			},
			{
				featureType: 'road.arterial',
				elementType: 'labels.icon',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'transit',
				elementType: 'all',
				stylers: [{ visibility: 'off' }],
			},
			{
				featureType: 'water',
				elementType: 'all',
				stylers: [{ color: '#d1d1d1' }, { visibility: 'on' }],
			},
		]);

		const svg = window.btoa(`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="${primaryColor}"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>`);

		new google.maps.Marker({
			map: map,
			position: new google.maps.LatLng(lat, lng),
			draggable: false,
			clickable: false,
			icon: {
				url: `data:image/svg+xml;base64,${svg}`,
				scaledSize: new google.maps.Size(36, 36), // Größe des Icons
			}
		});
	}
});
